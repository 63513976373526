import { Flags } from '../assets'
import { LocalStorage } from '../store/LocalStorage';

export enum ILanguages {
  'af-ZA' = 'af-ZA',
  'ar-DZ' = 'ar-DZ',
  'ar-BH' = 'ar-BH',
  'ar-EG' = 'ar-EG',
  'ar-IQ' = 'ar-IQ',
  //'ar-IL' = 'ar-IL',
  'ar-JO' = 'ar-JO',
  'ar-KW' = 'ar-KW',
  'ar-LB' = 'ar-LB',
  'ar-MA' = 'ar-MA',
  'ar-OM' = 'ar-OM',
  'ar-QA' = 'ar-QA',
  'ar-SA' = 'ar-SA',
  //'ar-PS' = 'ar-PS',
  'ar-TN' = 'ar-TN',
  'ar-AE' = 'ar-AE',
  'ar-YE' = 'ar-YE',
  //'bn-BD' = 'bn-BD',
  'bg-BG' = 'bg-BG',
  'ca-ES' = 'ca-ES',
  'zh-HK' = 'zh-HK',
  'zh-CN' = 'zh-CN',
  'zh-TW' = 'zh-TW',
  'hr-HR' = 'hr-HR',
  'cs-CZ' = 'cs-CZ',
  'da-DK' = 'da-DK',
  'nl-BE' = 'nl-BE',
  'nl-NL' = 'nl-NL',
  'en-AU' = 'en-AU',
  'en-CA' = 'en-CA',
  //'en-GH' = 'en-GH',
  'en-IN' = 'en-IN',
  //'en-IE' = 'en-IE',
  //'en-KE' = 'en-KE',
  'en-NZ' = 'en-NZ',
  //'en-NG' = 'en-NG',
  //'en-PH' = 'en-PH',
  'en-SG' = 'en-SG',
  //'en-TZ' = 'en-TZ',
  'en-GB' = 'en-GB',
  'en-US' = 'en-US',
  'et-EE' = 'et-EE',
  'fil-PH' = 'fil-PH',
  'fi-FI' = 'fi-FI',
  'fr-BE' = 'fr-BE',
  'fr-CA' = 'fr-CA',
  'fr-FR' = 'fr-FR',
  'fr-CH' = 'fr-CH',
  'de-AT' = 'de-AT',
  'de-DE' = 'de-DE',
  'de-CH' = 'de-CH',
  'el-GR' = 'el-GR',
  //'iw-IL' = 'iw-IL',
  'hi-IN' = 'hi-IN',
  'hu-HU' = 'hu-HU',
  //'is-IS' = 'is-IS',
  'id-ID' = 'id-ID',
  'it-IT' = 'it-IT',
  'it-CH' = 'it-CH',
  'ja-JP' = 'ja-JP',
  'ko-KR' = 'ko-KR',
  'lv-LV' = 'lv-LV',
  'lt-LT' = 'lt-LT',
  'ms-MY' = 'ms-MY',
  //'no-NO' = 'no-NO',
  'pl-PL' = 'pl-PL',
  'pt-BR' = 'pt-BR',
  'pt-PT' = 'pt-PT',
  'ro-RO' = 'ro-RO',
  'ru-RU' = 'ru-RU',
  'sr-RS' = 'sr-RS',
  'sk-SK' = 'sk-SK',
  'sl-SI' = 'sl-SI',
  'es-AR' = 'es-AR',
  'es-BO' = 'es-BO',
  'es-CL' = 'es-CL',
  'es-CO' = 'es-CO',
  'es-CR' = 'es-CR',
  'es-DO' = 'es-DO',
  'es-EC' = 'es-EC',
  'es-SV' = 'es-SV',
  'es-GT' = 'es-GT',
  'es-HN' = 'es-HN',
  'es-MX' = 'es-MX',
  'es-NI' = 'es-NI',
  'es-PA' = 'es-PA',
  'es-PY' = 'es-PY',
  'es-PE' = 'es-PE',
  'es-PR' = 'es-PR',
  'es-ES' = 'es-ES',
  'es-UY' = 'es-UY',
  'es-VE' = 'es-VE',
  'sv-SE' = 'sv-SE',
  //'ta-LK' = 'ta-LK',
  'te-IN' = 'te-IN',
  'th-TH' = 'th-TH',
  'tr-TR' = 'tr-TR',
  'uk-UA' = 'uk-UA',
  //'ur-PK' = 'ur-PK',
  'vi-VN' = 'vi-VN',
}

export interface ILanguageObject {
  id: ILanguages;
  img: string;
  // TO DO!: make required
  // label: string;
  sttLanguageCode: string;
  ttsLanguageCode: string;
  translateCode: string;
  languageName: string;
  gglVoicetype?: string;
  gglVoiceName?: string;
  isOnlyAzure?: boolean;
  azureStyle?: string;
  azureVoiceName?: string;
  summaryAgenda?: string; // クライアント画面で表示する会議全体の要約のタイトル名
  defaultAgenda?: string; // どのアジェンダを選択していても内容が追加されるタイトル名
  initialSummary?: string; // クライアント画面で表示する会議全体の要約の初期表示
  initialMinutes?: string; // クライアント画面で表示するアジェンダごとの初期表示
}

export const LanguageList: Record<ILanguages, ILanguageObject> = {
  'ja-JP': {
    img: Flags.ja_JP,
    id: ILanguages['ja-JP'],
    // label: I18n.t('l.ja-JP'),
    languageName: 'Japanese (Japan)',
    sttLanguageCode: 'ja-JP',
    ttsLanguageCode: 'ja-JP',
    translateCode: 'ja',
    // gglVoicetype: 'WaveNet',
    // gglVoiceName: 'ja-JP-Wavenet-A',
    gglVoicetype: 'Neural2',
    gglVoiceName: 'ja-JP-Neural2-B',
    summaryAgenda: 'AI要約',
    defaultAgenda: '議事録',
    initialSummary: '議事内容を定期的に要約します。',
    initialMinutes: '議題に沿った議事録を作成します。',
  },
  'en-US': {
    img: Flags.en_US,
    id: ILanguages['en-US'],
    // label: I18n.t('l.en-US'),
    languageName: 'English (US)',
    sttLanguageCode: 'en-US',
    ttsLanguageCode: 'en-US',
    translateCode: 'en',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'en-US-Wavenet-A',
    summaryAgenda: 'Meeting summary',
    defaultAgenda: 'Meeting Minutes',
    initialSummary: 'Summarize the agenda regularly.',
    initialMinutes: 'Create minutes according to the agenda.',
  },
  'es-ES': {
    img: Flags.es_ES,
    id: ILanguages['es-ES'],
    // label: I18n.t('l.es-ES'),
    languageName: 'Spanish (Spain)',
    sttLanguageCode: 'es-ES',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'zh-CN': {
    img: Flags.zh_CN,
    id: ILanguages['zh-CN'],
    // label: I18n.t('l.zh-CN'),
    sttLanguageCode: 'zh-CN',
    ttsLanguageCode: 'zh-CN',
    translateCode: 'zh',
    languageName: 'Chinese (Mandarin, Simplified)',
    azureStyle: 'Optimized for story narrating',
    azureVoiceName: 'zh-CN-YunyeNeural',
    isOnlyAzure: true,
    summaryAgenda: '会议总结',
    defaultAgenda: '会议记录',
    initialSummary: '定期总结议题。',
    initialMinutes: '根据议题创建会议记录。',
  },
  'ko-KR': {
    img: Flags.ko_KR,
    id: ILanguages['ko-KR'],
    // label: I18n.t('l.ko-KR'),
    languageName: 'Korean (South Korea)',
    sttLanguageCode: 'ko-KR',
    ttsLanguageCode: 'ko-KR',
    translateCode: 'ko',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ko-KR-Wavenet-A',
  },
  'fr-FR': {
    img: Flags.fr_FR,
    id: ILanguages['fr-FR'],
    // label: I18n.t('l.fr-FR'),
    languageName: 'French (France)',
    sttLanguageCode: 'fr-FR',
    ttsLanguageCode: 'fr-FR',
    translateCode: 'fr',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'fr-FR-Wavenet-A',
  },
  'th-TH': {
    img: Flags.th_TH,
    id: ILanguages['th-TH'],
    // label: I18n.t('l.th-TH'),
    languageName: 'Thai (Thailand)',
    sttLanguageCode: 'th-TH',
    ttsLanguageCode: 'th-TH',
    translateCode: 'th',
    gglVoicetype: 'Standard',
    gglVoiceName: 'th-TH-Standard-A',
  },
  'id-ID': {
    img: Flags.id_ID,
    id: ILanguages['id-ID'],
    // label: I18n.t('l.id-ID'),
    languageName: 'Indonesian (Indonesia)',
    sttLanguageCode: 'id-ID',
    ttsLanguageCode: 'id-ID',
    translateCode: 'id',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'id-ID-Wavenet-A',
    summaryAgenda: 'Ringkasan Rapat',
    defaultAgenda: 'Notulen Rapat',
    initialSummary: 'Rangkuman agenda secara teratur.',
    initialMinutes: 'Buat notulen sesuai dengan agenda.',
  },
  'vi-VN': {
    img: Flags.vi_VN,
    id: ILanguages['vi-VN'],
    // label: I18n.t('l.vi-VN'),
    languageName: 'Vietnamese (Vietnam)',
    sttLanguageCode: 'vi-VN',
    ttsLanguageCode: 'vi-VN',
    translateCode: 'vi',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'vi-VN-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'is-IS': {
  //   img: Flags.is_IS,
  //   id: ILanguages['is-IS'],
  //   // label: I18n.t('l.is-IS'),
  //   languageName: 'Icelandic (Iceland)',
  //   sttLanguageCode: 'is-IS',
  //   ttsLanguageCode: 'is-IS',
  //   translateCode: 'is',
  //   gglVoicetype: 'Standard',
  //   gglVoiceName: 'is-is-Standard-A',
  // },
  // Azure STT では存在しない
  // 'en-IE': {
  //   img: Flags.en_IE,
  //   id: ILanguages['en-IE'],
  //   // label: I18n.t('l.en-IE'),
  //   sttLanguageCode: 'en-IE',
  //   ttsLanguageCode: 'en-IE',
  //   translateCode: 'en',
  //   languageName: 'English (Ireland)',
  //   azureStyle: 'General',
  //   azureVoiceName: 'en-IE-ConnorNeural',
  //   isOnlyAzure: true,
  // },
  'ar-AE': {
    img: Flags.ar_AE,
    id: ILanguages['ar-AE'],
    // label: I18n.t('l.ar-AE'),
    languageName: 'Arabic (United Arab Emirates)',
    sttLanguageCode: 'ar-AE',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'ar-DZ': {
    img: Flags.ar_DZ,
    id: ILanguages['ar-DZ'],
    // label: I18n.t('l.ar-DZ'),
    languageName: 'Arabic (Algeria)',
    sttLanguageCode: 'ar-DZ',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'es-AR': {
    img: Flags.es_AR,
    id: ILanguages['es-AR'],
    // label: I18n.t('l.es-AR'),
    languageName: 'Spanish (Argentina)',
    sttLanguageCode: 'es-AR',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'ar-YE': {
    img: Flags.ar_YE,
    id: ILanguages['ar-YE'],
    // label: I18n.t('l.ar-YE'),
    languageName: 'Arabic (Yemen)',
    sttLanguageCode: 'ar-YE',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'en-GB': {
    img: Flags.en_GB,
    id: ILanguages['en-GB'],
    // label: I18n.t('l.en-GB'),
    languageName: 'English (UK)',
    sttLanguageCode: 'en-GB',
    ttsLanguageCode: 'en-GB',
    translateCode: 'en',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'en-GB-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'ar-IL': {
  //   img: Flags.ar_IL,
  //   id: ILanguages['ar-IL'],
  //   // label: I18n.t('l.ar-IL'),
  //   languageName: 'Arabic (Israel)',
  //   sttLanguageCode: 'ar-IL',
  //   ttsLanguageCode: 'ar-XA',
  //   translateCode: 'ar',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'ar-XA-Wavenet-A',
  // },
  'ar-MA': {
    img: Flags.ar_MA,
    id: ILanguages['ar-MA'],
    // label: I18n.t('l.ar-MA'),
    languageName: 'Arabic (Morocco)',
    sttLanguageCode: 'ar-MA',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'iw-IL': {
  //   img: Flags.iw_IL,
  //   id: ILanguages['iw-IL'],
  //   // label: I18n.t('l.iw-IL'),
  //   sttLanguageCode: 'iw-IL',
  //   ttsLanguageCode: 'he-IL',
  //   translateCode: 'he',
  //   languageName: 'Hebrew (Israel)',
  //   azureStyle: 'General',
  //   azureVoiceName: 'he-IL-AvriNeural',
  //   isOnlyAzure: true,
  // },
  'it-IT': {
    img: Flags.it_IT,
    id: ILanguages['it-IT'],
    // label: I18n.t('l.it-IT'),
    languageName: 'Italian (Italy)',
    sttLanguageCode: 'it-IT',
    ttsLanguageCode: 'it-IT',
    translateCode: 'it',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'it-IT-Wavenet-A',
  },
  'ar-IQ': {
    img: Flags.ar_IQ,
    id: ILanguages['ar-IQ'],
    // label: I18n.t('l.ar-IQ'),
    languageName: 'Arabic (Iraq)',
    sttLanguageCode: 'ar-IQ',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'en-IN': {
    img: Flags.en_IN,
    id: ILanguages['en-IN'],
    // label: I18n.t('l.en-IN'),
    languageName: 'English (India)',
    sttLanguageCode: 'en-IN',
    ttsLanguageCode: 'en-IN',
    translateCode: 'en',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'en-IN-Wavenet-A',
  },
  'hi-IN': {
    img: Flags.hi_IN,
    id: ILanguages['hi-IN'],
    // label: I18n.t('l.hi-IN'),
    languageName: 'Hindi (India)',
    sttLanguageCode: 'hi-IN',
    ttsLanguageCode: 'hi-IN',
    translateCode: 'hi',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'hi-IN-Wavenet-A',
  },
  'te-IN': {
    img: Flags.te_IN,
    id: ILanguages['te-IN'],
    // label: I18n.t('l.te-IN'),
    languageName: 'Telugu (India)',
    sttLanguageCode: 'te-IN',
    ttsLanguageCode: 'te-IN',
    translateCode: 'te',
    gglVoicetype: 'Standard',
    gglVoiceName: 'te-IN-Standard-A',
  },
  'uk-UA': {
    img: Flags.uk_UA,
    id: ILanguages['uk-UA'],
    // label: I18n.t('l.uk-UA'),
    languageName: 'Ukrainian (Ukraine)',
    sttLanguageCode: 'uk-UA',
    ttsLanguageCode: 'uk-UA',
    translateCode: 'uk',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'uk-UA-Wavenet-A',
  },
  'es-UY': {
    img: Flags.es_UY,
    id: ILanguages['es-UY'],
    // label: I18n.t('l.es-UY'),
    languageName: 'Spanish (Uruguay)',
    sttLanguageCode: 'es-UY',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'es-EC': {
    img: Flags.es_EC,
    id: ILanguages['es-EC'],
    // label: I18n.t('l.es-EC'),
    languageName: 'Spanish (Ecuador)',
    sttLanguageCode: 'es-EC',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'ar-EG': {
    img: Flags.ar_EG,
    id: ILanguages['ar-EG'],
    // label: I18n.t('l.ar-EG'),
    sttLanguageCode: 'ar-EG',
    ttsLanguageCode: 'ar-EG',
    translateCode: 'ar',
    languageName: 'Arabic (Egypt)',
    azureStyle: 'General',
    azureVoiceName: 'ar-EG-ShakirNeural',
    isOnlyAzure: true,
  },
  'et-EE': {
    img: Flags.et_EE,
    id: ILanguages['et-EE'],
    // label: I18n.t('l.et-EE'),
    sttLanguageCode: 'et-EE',
    ttsLanguageCode: 'et-EE',
    translateCode: 'et',
    languageName: 'Estonian (Estonia)',
    azureStyle: 'General',
    azureVoiceName: 'et-EE-KertNeural',
    isOnlyAzure: true,
  },
  'es-SV': {
    img: Flags.es_SV,
    id: ILanguages['es-SV'],
    // label: I18n.t('l.es-SV'),
    languageName: 'Spanish (El Salvador)',
    sttLanguageCode: 'es-SV',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'ar-OM': {
    img: Flags.ar_OM,
    id: ILanguages['ar-OM'],
    // label: I18n.t('l.ar-OM'),
    languageName: 'Arabic (Oman)',
    sttLanguageCode: 'ar-OM',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'nl-NL': {
    img: Flags.nl_NL,
    id: ILanguages['nl-NL'],
    // label: I18n.t('l.nl-NL'),
    languageName: 'Dutch (Netherlands)',
    sttLanguageCode: 'nl-NL',
    ttsLanguageCode: 'nl-NL',
    translateCode: 'nl',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'nl-NL-Wavenet-A',
  },
  'en-AU': {
    img: Flags.en_AU,
    id: ILanguages['en-AU'],
    // label: I18n.t('l.en-AU'),
    languageName: 'English (Australia)',
    sttLanguageCode: 'en-AU',
    ttsLanguageCode: 'en-AU',
    translateCode: 'en',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'en-AU-Wavenet-A',
  },
  'de-AT': {
    img: Flags.de_AT,
    id: ILanguages['de-AT'],
    // label: I18n.t('l.de-AT'),
    sttLanguageCode: 'de-AT',
    ttsLanguageCode: 'de-AT',
    translateCode: 'de',
    languageName: 'German (Austria)',
    azureStyle: 'General',
    azureVoiceName: 'de-AT-JonasNeural',
    isOnlyAzure: true,
  },
  'ar-QA': {
    img: Flags.ar_QA,
    id: ILanguages['ar-QA'],
    // label: I18n.t('l.ar-QA'),
    languageName: 'Arabic (Qatar)',
    sttLanguageCode: 'ar-QA',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'en-CA': {
    img: Flags.en_CA,
    id: ILanguages['en-CA'],
    // label: I18n.t('l.en-CA'),
    sttLanguageCode: 'en-CA',
    ttsLanguageCode: 'en-CA',
    translateCode: 'en',
    languageName: 'English (Canada)',
    azureStyle: 'General',
    azureVoiceName: 'en-CA-LiamNeural',
    isOnlyAzure: true,
  },
  'fr-CA': {
    img: Flags.fr_CA,
    id: ILanguages['fr-CA'],
    // label: I18n.t('l.fr-CA'),
    languageName: 'French (Canada)',
    sttLanguageCode: 'fr-CA',
    ttsLanguageCode: 'fr-CA',
    translateCode: 'fr',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'fr-CA-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'en-GH': {
  //   img: Flags.en_GH,
  //   id: ILanguages['en-GH'],
  //   // label: I18n.t('l.en-GH'),
  //   languageName: 'English (Ghana)',
  //   sttLanguageCode: 'en-GH',
  //   ttsLanguageCode: 'en-GB',
  //   translateCode: 'en',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'en-GB-Wavenet-A',
  // },
  'el-GR': {
    img: Flags.el_GR,
    id: ILanguages['el-GR'],
    // label: I18n.t('l.el-GR'),
    languageName: 'Greek (Greece)',
    sttLanguageCode: 'el-GR',
    ttsLanguageCode: 'el-GR',
    translateCode: 'el',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'el-GR-Wavenet-A',
  },
  'ar-KW': {
    img: Flags.ar_KW,
    id: ILanguages['ar-KW'],
    // label: I18n.t('l.ar-KW'),
    languageName: 'Arabic (Kuwait)',
    sttLanguageCode: 'ar-KW',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'hr-HR': {
    img: Flags.hr_HR,
    id: ILanguages['hr-HR'],
    // label: I18n.t('l.hr-HR'),
    sttLanguageCode: 'hr-HR',
    ttsLanguageCode: 'hr-HR',
    translateCode: 'hr',
    languageName: 'Croatian (Croatia)',
    azureStyle: 'General',
    azureVoiceName: 'hr-HR-SreckoNeural',
    isOnlyAzure: true,
  },
  'es-GT': {
    img: Flags.es_GT,
    id: ILanguages['es-GT'],
    // label: I18n.t('l.es-GT'),
    languageName: 'Spanish (Guatemala)',
    sttLanguageCode: 'es-GT',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  // Azure STT では存在しない
  // 'en-KE': {
  //   img: Flags.en_KE,
  //   id: ILanguages['en-KE'],
  //   // label: I18n.t('l.en-KE'),
  //   languageName: 'English (Kenya)',
  //   sttLanguageCode: 'en-KE',
  //   ttsLanguageCode: 'en-GB',
  //   translateCode: 'en',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'en-GB-Wavenet-A',
  // },
  'es-CR': {
    img: Flags.es_CR,
    id: ILanguages['es-CR'],
    // label: I18n.t('l.es-CR'),
    languageName: 'Spanish (Costa Rica)',
    sttLanguageCode: 'es-CR',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'es-CO': {
    img: Flags.es_CO,
    id: ILanguages['es-CO'],
    // label: I18n.t('l.es-CO'),
    languageName: 'Spanish (Colombia)',
    sttLanguageCode: 'es-CO',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'ar-SA': {
    img: Flags.ar_SA,
    id: ILanguages['ar-SA'],
    // label: I18n.t('l.ar-SA'),
    sttLanguageCode: 'ar-SA',
    ttsLanguageCode: 'ar-SA',
    translateCode: 'ar',
    languageName: 'Arabic (Saudi Arabia)',
    azureStyle: 'General',
    azureVoiceName: 'ar-SA-HamedNeural',
    isOnlyAzure: true,
  },
  'en-SG': {
    img: Flags.en_SG,
    id: ILanguages['en-SG'],
    // label: I18n.t('l.en-SG'),
    languageName: 'English (Singapore)',
    sttLanguageCode: 'en-SG',
    ttsLanguageCode: 'en-GB',
    translateCode: 'en',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'en-GB-Wavenet-A',
  },
  'fr-CH': {
    img: Flags.fr_CH,
    id: ILanguages['fr-CH'],
    // label: I18n.t('l.fr-CH'),
    sttLanguageCode: 'fr-CH',
    ttsLanguageCode: 'fr-CH',
    translateCode: 'fr',
    languageName: 'French (Switzerland)',
    azureStyle: 'General',
    azureVoiceName: 'fr-CH-FabriceNeural',
    isOnlyAzure: true,
  },
  'de-CH': {
    img: Flags.de_CH,
    id: ILanguages['de-CH'],
    // label: I18n.t('l.de-CH'),
    sttLanguageCode: 'de-CH',
    ttsLanguageCode: 'de-CH',
    translateCode: 'de',
    languageName: 'German (Switzerland)',
    azureStyle: 'General',
    azureVoiceName: 'de-CH-JanNeural',
    isOnlyAzure: true,
  },
  'it-CH': {
    img: Flags.it_CH,
    id: ILanguages['it-CH'],
    // label: I18n.t('l.it-CH'),
    languageName: 'Italian (Switzerland)',
    sttLanguageCode: 'it-CH',
    ttsLanguageCode: 'it-IT',
    translateCode: 'it',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'it-IT-Wavenet-A',
  },
  'sv-SE': {
    img: Flags.sv_SE,
    id: ILanguages['sv-SE'],
    // label: I18n.t('l.sv-SE'),
    languageName: 'Swedish (Sweden)',
    sttLanguageCode: 'sv-SE',
    ttsLanguageCode: 'sv-SE',
    translateCode: 'sv',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'sv-SE-Wavenet-A',
  },
  'ca-ES': {
    img: Flags.ca_ES,
    id: ILanguages['ca-ES'],
    // label: I18n.t('l.ca-ES'),
    languageName: 'Catalan (Spain)',
    sttLanguageCode: 'ca-ES',
    ttsLanguageCode: 'ca-ES',
    translateCode: 'ca',
    gglVoicetype: 'Standard',
    gglVoiceName: 'ca-es-Standard-A',
  },
  // Azure STT では存在しない
  // 'ta-LK': {
  //   img: Flags.ta_LK,
  //   id: ILanguages['ta-LK'],
  //   // label: I18n.t('l.ta-LK'),
  //   languageName: 'Tamil (Sri Lanka)',
  //   sttLanguageCode: 'ta-LK',
  //   ttsLanguageCode: 'ta-IN',
  //   translateCode: 'ta',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'ta-IN-Wavenet-A',
  // },
  'sk-SK': {
    img: Flags.sk_SK,
    id: ILanguages['sk-SK'],
    // label: I18n.t('l.sk-SK'),
    languageName: 'Slovak (Slovakia)',
    sttLanguageCode: 'sk-SK',
    ttsLanguageCode: 'sk-SK',
    translateCode: 'sk',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'sk-SK-Wavenet-A',
  },
  'sl-SI': {
    img: Flags.sl_SI,
    id: ILanguages['sl-SI'],
    // label: I18n.t('l.sl-SI'),
    sttLanguageCode: 'sl-SI',
    ttsLanguageCode: 'sl-SI',
    translateCode: 'sl',
    languageName: 'Slovenian (Slovenia)',
    azureStyle: 'General',
    azureVoiceName: 'sl-SI-RokNeural',
    isOnlyAzure: true,
  },
  'sr-RS': {
    img: Flags.sr_RS,
    id: ILanguages['sr-RS'],
    // label: I18n.t('l.sr-RS'),
    languageName: 'Serbian (Cyrillic)',
    sttLanguageCode: 'sr-RS',
    ttsLanguageCode: 'sr-RS',
    translateCode: 'sr',
    gglVoicetype: 'Standard',
    gglVoiceName: 'sr-rs-Standard-A',
  },
  // Azure STT では存在しない
  // 'en-TZ': {
  //   img: Flags.en_TZ,
  //   id: ILanguages['en-TZ'],
  //   // label: I18n.t('l.en-TZ'),
  //   languageName: 'English (Tanzania)',
  //   sttLanguageCode: 'en-TZ',
  //   ttsLanguageCode: 'en-GB',
  //   translateCode: 'en',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'en-GB-Wavenet-A',
  // },
  'cs-CZ': {
    img: Flags.cs_CZ,
    id: ILanguages['cs-CZ'],
    // label: I18n.t('l.cs-CZ'),
    languageName: 'Czech (Czech Republic)',
    sttLanguageCode: 'cs-CZ',
    ttsLanguageCode: 'cs-CZ',
    translateCode: 'cs',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'cs-CZ-Wavenet-A',
  },
  'ar-TN': {
    img: Flags.ar_TN,
    id: ILanguages['ar-TN'],
    // label: I18n.t('l.ar-TN'),
    languageName: 'Arabic (Tunisia)',
    sttLanguageCode: 'ar-TN',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'es-CL': {
    img: Flags.es_CL,
    id: ILanguages['es-CL'],
    // label: I18n.t('l.es-CL'),
    languageName: 'Spanish (Chile)',
    sttLanguageCode: 'es-CL',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'da-DK': {
    img: Flags.da_DK,
    id: ILanguages['da-DK'],
    // label: I18n.t('l.da-DK'),
    languageName: 'Danish (Denmark)',
    sttLanguageCode: 'da-DK',
    ttsLanguageCode: 'da-DK',
    translateCode: 'da',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'da-DK-Wavenet-A',
  },
  'tr-TR': {
    img: Flags.tr_TR,
    id: ILanguages['tr-TR'],
    // label: I18n.t('l.tr-TR'),
    languageName: 'Turkish (Turkey)',
    sttLanguageCode: 'tr-TR',
    ttsLanguageCode: 'tr-TR',
    translateCode: 'tr',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'tr-TR-Wavenet-A',
  },
  'de-DE': {
    img: Flags.de_DE,
    id: ILanguages['de-DE'],
    // label: I18n.t('l.de-DE'),
    languageName: 'German (Germany)',
    sttLanguageCode: 'de-DE',
    ttsLanguageCode: 'de-DE',
    translateCode: 'de',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'de-DE-Wavenet-A',
  },
  'es-DO': {
    img: Flags.es_DO,
    id: ILanguages['es-DO'],
    // label: I18n.t('l.es-DO'),
    languageName: 'Spanish (Dominican Republic)',
    sttLanguageCode: 'es-DO',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  // Azure STT では存在しない
  // 'en-NG': {
  //   img: Flags.en_NG,
  //   id: ILanguages['en-NG'],
  //   // label: I18n.t('l.en-NG'),
  //   languageName: 'English (Nigeria)',
  //   sttLanguageCode: 'en-NG',
  //   ttsLanguageCode: 'en-GB',
  //   translateCode: 'en',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'en-GB-Wavenet-A',
  // },
  'es-NI': {
    img: Flags.es_NI,
    id: ILanguages['es-NI'],
    // label: I18n.t('l.es-NI'),
    languageName: 'Spanish (Nicaragua)',
    sttLanguageCode: 'es-NI',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'en-NZ': {
    img: Flags.en_NZ,
    id: ILanguages['en-NZ'],
    // label: I18n.t('l.en-NZ'),
    languageName: 'English (New Zealand)',
    sttLanguageCode: 'en-NZ',
    ttsLanguageCode: 'en-GB',
    translateCode: 'en',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'en-GB-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'no-NO': {
  //   img: Flags.no_NO,
  //   id: ILanguages['no-NO'],
  //   // label: I18n.t('l.no-NO'),
  //   sttLanguageCode: 'no-NO',
  //   ttsLanguageCode: 'nb-NO',
  //   translateCode: 'no',
  //   languageName: 'Norwegian (Bokmål, Norway)',
  //   azureStyle: 'General',
  //   azureVoiceName: 'nb-NO-FinnNeural',
  //   isOnlyAzure: true,
  // },
  'hu-HU': {
    img: Flags.hu_HU,
    id: ILanguages['hu-HU'],
    // label: I18n.t('l.hu-HU'),
    languageName: 'Hungarian (Hungary)',
    sttLanguageCode: 'hu-HU',
    ttsLanguageCode: 'hu-HU',
    translateCode: 'hu',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'hu-HU-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'bn-BD': {
  //   img: Flags.bn_BD,
  //   id: ILanguages['bn-BD'],
  //   // label: I18n.t('l.bn-BD'),
  //   languageName: 'Bengali (Bangladesh)',
  //   sttLanguageCode: 'bn-BD',
  //   ttsLanguageCode: 'bn-IN',
  //   translateCode: 'bn',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'bn-IN-Wavenet-A',
  // },
  'ar-BH': {
    img: Flags.ar_BH,
    id: ILanguages['ar-BH'],
    // label: I18n.t('l.ar-BH'),
    languageName: 'Arabic (Bahrain)',
    sttLanguageCode: 'ar-BH',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  // Azure STT では存在しない
  // 'ur-PK': {
  //   img: Flags.ur_PK,
  //   id: ILanguages['ur-PK'],
  //   // label: I18n.t('l.ur-PK'),
  //   sttLanguageCode: 'ur-PK',
  //   ttsLanguageCode: 'ur-PK',
  //   translateCode: 'ur',
  //   languageName: 'Urdu (Pakistan)',
  //   azureStyle: 'General',
  //   azureVoiceName: 'ur-PK-AsadNeuralNew',
  //   isOnlyAzure: true,
  // },
  'es-PA': {
    img: Flags.es_PA,
    id: ILanguages['es-PA'],
    // label: I18n.t('l.es-PA'),
    languageName: 'Spanish (Panama)',
    sttLanguageCode: 'es-PA',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'es-PY': {
    img: Flags.es_PY,
    id: ILanguages['es-PY'],
    // label: I18n.t('l.es-PY'),
    languageName: 'Spanish (Paraguay)',
    sttLanguageCode: 'es-PY',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  // Azure STT では存在しない
  // 'ar-PS': {
  //   img: Flags.ar_PS,
  //   id: ILanguages['ar-PS'],
  //   // label: I18n.t('l.ar-PS'),
  //   languageName: 'Arabic (State of Palestine)',
  //   sttLanguageCode: 'ar-PS',
  //   ttsLanguageCode: 'ar-XA',
  //   translateCode: 'ar',
  //   gglVoicetype: 'WaveNet',
  //   gglVoiceName: 'ar-XA-Wavenet-A',
  // },
  // Azure STT では存在しない
  // 'en-PH': {
  //   img: Flags.en_PH,
  //   id: ILanguages['en-PH'],
  //   // label: I18n.t('l.en-PH'),
  //   sttLanguageCode: 'en-PH',
  //   ttsLanguageCode: 'en-PH',
  //   translateCode: 'en',
  //   languageName: 'English (Philippines)',
  //   azureStyle: 'General',
  //   azureVoiceName: 'en-PH-JamesNeural New',
  //   isOnlyAzure: true,
  // },
  'fil-PH': {
    img: Flags.fil_P,
    id: ILanguages['fil-PH'],
    // label: I18n.t('l.fil-PH'),
    languageName: 'Filipino (Philippines)',
    sttLanguageCode: 'fil-PH',
    ttsLanguageCode: 'fil-PH',
    translateCode: 'fil',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'fil-PH-Wavenet-A',
  },
  'fi-FI': {
    img: Flags.fi_FI,
    id: ILanguages['fi-FI'],
    // label: I18n.t('l.fi-FI'),
    languageName: 'Finnish (Finland)',
    sttLanguageCode: 'fi-FI',
    ttsLanguageCode: 'fi-FI',
    translateCode: 'fi',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'fi-FI-Wavenet-A',
  },
  'pt-BR': {
    img: Flags.pt_BR,
    id: ILanguages['pt-BR'],
    // label: I18n.t('l.pt-BR'),
    languageName: 'Portuguese (Brazil)',
    sttLanguageCode: 'pt-BR',
    ttsLanguageCode: 'pt-BR',
    translateCode: 'pt',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'pt-BR-Wavenet-A',
  },
  'bg-BG': {
    img: Flags.bg_BG,
    id: ILanguages['bg-BG'],
    // label: I18n.t('l.bg-BG'),
    languageName: 'Bulgarian (Bulgaria)',
    sttLanguageCode: 'bg-BG',
    ttsLanguageCode: 'bg-BG',
    translateCode: 'bg',
    gglVoicetype: 'Standard',
    gglVoiceName: 'bg-bg-Standard-A',
  },
  'es-PR': {
    img: Flags.es_PR,
    id: ILanguages['es-PR'],
    // label: I18n.t('l.es-PR'),
    languageName: 'Spanish (Puerto Rico)',
    sttLanguageCode: 'es-PR',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'es-VE': {
    img: Flags.es_VE,
    id: ILanguages['es-VE'],
    // label: I18n.t('l.es-VE'),
    languageName: 'Spanish (Venezuela)',
    sttLanguageCode: 'es-VE',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'nl-BE': {
    img: Flags.nl_BE,
    id: ILanguages['nl-BE'],
    // label: I18n.t('l.nl-BE'),
    sttLanguageCode: 'nl-BE',
    ttsLanguageCode: 'nl-BE',
    translateCode: 'nl',
    languageName: 'Dutch (Belgium)',
    azureStyle: 'General',
    azureVoiceName: 'nl-BE-ArnaudNeural New',
    isOnlyAzure: true,
  },
  'fr-BE': {
    img: Flags.fr_BE,
    id: ILanguages['fr-BE'],
    // label: I18n.t('l.fr-BE'),
    sttLanguageCode: 'fr-BE',
    ttsLanguageCode: 'fr-BE',
    translateCode: 'fr',
    languageName: 'French (Belgium)',
    azureStyle: 'General',
    azureVoiceName: 'fr-BE-GerardNeural New',
    isOnlyAzure: true,
  },
  'es-PE': {
    img: Flags.es_PE,
    id: ILanguages['es-PE'],
    // label: I18n.t('l.es-PE'),
    languageName: 'Spanish (Peru)',
    sttLanguageCode: 'es-PE',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'es-HN': {
    img: Flags.es_HN,
    id: ILanguages['es-HN'],
    // label: I18n.t('l.es-HN'),
    languageName: 'Spanish (Honduras)',
    sttLanguageCode: 'es-HN',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'es-BO': {
    img: Flags.es_BO,
    id: ILanguages['es-BO'],
    // label: I18n.t('l.es-BO'),
    languageName: 'Spanish (Bolivia)',
    sttLanguageCode: 'es-BO',
    ttsLanguageCode: 'es-ES',
    translateCode: 'es',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'es-ES-Wavenet-B',
  },
  'pt-PT': {
    img: Flags.pt_PT,
    id: ILanguages['pt-PT'],
    // label: I18n.t('l.pt-PT'),
    languageName: 'Portuguese (Portugal)',
    sttLanguageCode: 'pt-PT',
    ttsLanguageCode: 'pt-PT',
    translateCode: 'pt',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'pt-PT-Wavenet-A',
  },
  'pl-PL': {
    img: Flags.pl_PL,
    id: ILanguages['pl-PL'],
    // label: I18n.t('l.pl-PL'),
    languageName: 'Polish (Poland)',
    sttLanguageCode: 'pl-PL',
    ttsLanguageCode: 'pl-PL',
    translateCode: 'pl',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'pl-PL-Wavenet-A',
  },
  'ms-MY': {
    img: Flags.ms_MY,
    id: ILanguages['ms-MY'],
    // label: I18n.t('l.ms-MY'),
    sttLanguageCode: 'ms-MY',
    ttsLanguageCode: 'ms-MY',
    translateCode: 'ms',
    languageName: 'Malay (Malaysia)',
    azureStyle: 'General',
    azureVoiceName: 'ms-MY-OsmanNeural',
    isOnlyAzure: true,
  },
  'es-MX': {
    img: Flags.es_MX,
    id: ILanguages['es-MX'],
    // label: I18n.t('l.es-MX'),
    sttLanguageCode: 'es-MX',
    ttsLanguageCode: 'es-MX',
    translateCode: 'es',
    languageName: 'Spanish (Mexico)',
    azureStyle: 'General',
    azureVoiceName: 'es-MX-JorgeNeural',
    isOnlyAzure: true,
  },
  'ar-JO': {
    img: Flags.ar_JO,
    id: ILanguages['ar-JO'],
    // label: I18n.t('l.ar-JO'),
    languageName: 'Arabic (Jordan)',
    sttLanguageCode: 'ar-JO',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'lv-LV': {
    img: Flags.lv_LV,
    id: ILanguages['lv-LV'],
    // label: I18n.t('l.lv-LV'),
    languageName: 'Latvian (Latvia)',
    sttLanguageCode: 'lv-LV',
    ttsLanguageCode: 'lv-LV',
    translateCode: 'lv',
    gglVoicetype: 'Standard',
    gglVoiceName: 'lv-lv-Standard-A',
  },
  'lt-LT': {
    img: Flags.lt_LT,
    id: ILanguages['lt-LT'],
    // label: I18n.t('l.lt-LT'),
    sttLanguageCode: 'lt-LT',
    ttsLanguageCode: 'lt-LT',
    translateCode: 'lt',
    languageName: 'Lithuanian (Lithuania)',
    azureStyle: 'General',
    azureVoiceName: 'lt-LT-LeonasNeural',
    isOnlyAzure: true,
  },
  'ro-RO': {
    img: Flags.ro_RO,
    id: ILanguages['ro-RO'],
    // label: I18n.t('l.ro-RO'),
    languageName: 'Romanian (Romania)',
    sttLanguageCode: 'ro-RO',
    ttsLanguageCode: 'ro-RO',
    translateCode: 'ro',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ro-RO-Wavenet-A',
  },
  'ar-LB': {
    img: Flags.ar_LB,
    id: ILanguages['ar-LB'],
    // label: I18n.t('l.ar-LB'),
    languageName: 'Arabic (Lebanon)',
    sttLanguageCode: 'ar-LB',
    ttsLanguageCode: 'ar-XA',
    translateCode: 'ar',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ar-XA-Wavenet-A',
  },
  'ru-RU': {
    img: Flags.ru_RU,
    id: ILanguages['ru-RU'],
    // label: I18n.t('l.ru-RU'),
    languageName: 'Russian (Russia)',
    sttLanguageCode: 'ru-RU',
    ttsLanguageCode: 'ru-RU',
    translateCode: 'ru',
    gglVoicetype: 'WaveNet',
    gglVoiceName: 'ru-RU-Wavenet-A',
  },
  'zh-HK': {
    img: Flags.zh_HK,
    id: ILanguages['zh-HK'],
    // label: I18n.t('l.zh-HK'),
    sttLanguageCode: 'zh-HK',
    ttsLanguageCode: 'zh-HK',
    // NEED TO CHECK TRANSLATION CODE
    translateCode: 'zh-TW',
    languageName: 'Cantonese (Hong Kong)',
    azureStyle: 'General',
    azureVoiceName: 'zh-HK-WanLungNeural',
    isOnlyAzure: true,
  },
  'zh-TW': {
    img: Flags.zh_TW,
    id: ILanguages['zh-TW'],
    // label: I18n.t('l.zh-TW'),
    sttLanguageCode: 'zh-TW',
    ttsLanguageCode: 'zh-TW',
    translateCode: 'zh-TW',
    languageName: 'Taiwanese (Mandarin, Traditional)',
    azureStyle: 'General',
    azureVoiceName: 'zh-TW-YunJheNeural',
    isOnlyAzure: true,
    summaryAgenda: '會議總結',
    defaultAgenda: '會議記錄',
    initialSummary: '定期總結議題。',
    initialMinutes: '根據議題創建會議記錄。',
  },
  'af-ZA': {
    img: Flags.af_ZA,
    id: ILanguages['af-ZA'],
    // label: I18n.t('l.af-ZA'),
    languageName: 'Afrikaans (South Africa)',
    sttLanguageCode: 'af-ZA',
    ttsLanguageCode: 'af-ZA',
    translateCode: 'af',
    gglVoicetype: 'Standard',
    gglVoiceName: 'af-ZA-Standard-A',
  },
}

export const guestLanguage = (lang: string) => {
  // try getting rid of unwanted characters
  lang = lang.trim().replace('_', '-')

  // In the case of Google STT, all letters are returned in lowercase, so make the last two letters uppercase.
  let parts = lang.split('-');
  if (parts.length === 2) {
    lang = parts[0].toLowerCase() + '-' + parts[1].toUpperCase();
  }

  let langObj = LanguageList[lang as ILanguages]
  if (langObj) {
    // exact name found
    return langObj
  } else {
    // pick first match
    const keys = Object.keys(LanguageList)
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes(lang) || LanguageList[keys[i] as ILanguages].translateCode === lang) {
        return LanguageList[keys[i] as ILanguages]
      }
    }

    // found nothing
    return null
  }
}

export const getLanguageCodes = () => {
  // 主要言語と翻訳言語をまとめて送信する
  const languages: ILanguages[] = [];
  if (Array.isArray(LocalStorage.language) && LocalStorage.language.length !== 0) {
    languages.push(...LocalStorage.language);
  } else if (!Array.isArray(LocalStorage.language) && typeof LocalStorage.language as string === 'string') {
    languages.push(LocalStorage.language as unknown as ILanguages);
  }

  if (Array.isArray(LocalStorage.translationLanguage) && LocalStorage.translationLanguage.length !== 0) {
    let translateCode = LocalStorage.translationLanguage.filter(t => !languages.includes(t));
    languages.push(...translateCode);
  } else if (!Array.isArray(LocalStorage.translationLanguage) && typeof LocalStorage.translationLanguage === 'string') {
    if (!languages.includes(LocalStorage.translationLanguage)) {
      languages.push(LocalStorage.translationLanguage);
    }
  }

  if (languages.length === 0) {
    languages.push(ILanguages["ja-JP"]);
  }
  return languages;
};

export const getTranslateCodes = () => {
  // 主要言語と翻訳言語をまとめて送信する
  const languages: string[] = [];
  if (Array.isArray(LocalStorage.language) && LocalStorage.language.length !== 0) {
    const translateCode = LocalStorage.language.map(l => LanguageList[l].translateCode)
    languages.push(...translateCode);
  } else if (!Array.isArray(LocalStorage.language) && typeof LocalStorage.language as string === 'string') {
    const translateCode = LanguageList[LocalStorage.language as unknown as ILanguages].translateCode
    languages.push(translateCode);
  }

  if (Array.isArray(LocalStorage.translationLanguage) && LocalStorage.translationLanguage.length !== 0) {
    let translateCode = LocalStorage.language.map(l => LanguageList[l].translateCode)
    translateCode = translateCode.filter(t => !languages.includes(t));
    languages.push(...translateCode);
  } else if (!Array.isArray(LocalStorage.translationLanguage) && typeof LocalStorage.translationLanguage === 'string') {
    const translateCode = LanguageList[LocalStorage.translationLanguage].translateCode
    if (!languages.includes(translateCode)) {
      languages.push(translateCode);
    }
  }

  if (languages.length === 0) {
    languages.push('ja');
  }
  return languages;
};
