import React, { useEffect } from 'react';
import { useAgendaManagement } from '../hook/useAgendaManagement';
import { AgendaSelect } from './AgendaSelect';
import { MinutesList } from './MinutesList';
import { useTranslation } from 'react-i18next';
import { LanguageList } from '../util/language';
import { AgendaProps } from '../types/agenda';
import useQuery from '../hook/useQuery';
import { RealtimeMinute } from '../store/AdvisorState';
import { isDefaultMinutes } from '../util/util';

export const CurrentProgress: React.FC<AgendaProps> =
  ({
    roomOwnerId,
    meetingId,
    advisorStateInstance,
    agendaAdd,
    agendaEdit,
    agendaDelete,
    agendaChange
  }) => {
    const { i18n } = useTranslation();
    const { getSummaryFlag } = useQuery();
    const summaryFlag = getSummaryFlag();

    const {
      state,
      refs,
      actions
    } = useAgendaManagement(
      (LanguageList['ja-JP'].defaultAgenda) as string,
      agendaChange
    );

    useEffect(() => {
      advisorStateInstance.callbackRealtimeMinutesList = (
        minutes: RealtimeMinute[],
        targetAgenda?: string | null
      ) => {
        actions.updateAgendaList(minutes.map(m => m.agenda));

        if (targetAgenda) {
          const editValue = refs.agendaSelectRef.current?.getAgendaEditValue();
          if (isDefaultMinutes(targetAgenda) || !editValue) {
            actions.handleAgendaChange(targetAgenda, false);
          }
        }

        if (refs.minutesListRef.current) {
          refs.minutesListRef.current.setRealtimeMinutes(minutes);
          if (targetAgenda) {
            refs.minutesListRef.current.setAgenda(targetAgenda);
          }
        }
      };

      return () => {
        advisorStateInstance.callbackRealtimeMinutesList = () => { };
      };
    }, [advisorStateInstance, actions, refs]);

    useEffect(() => {
      i18n.on('languageChanged', actions.handleLanguageChange);
      return () => {
        i18n.off('languageChanged', actions.handleLanguageChange);
      };
    }, [actions.handleLanguageChange, i18n]);

    const onAgendaAdd = (val: string): boolean => {
      const result = actions.validateAgendaOperation('add', val);
      if (result.ok) {
        agendaAdd(result.value);
        return true;
      }
      alert(result.error.message);
      return false;
    };

    const onAgendaEdit = (val: string, targetAgenda: string): boolean => {
      const result = actions.validateAgendaOperation('edit', val, targetAgenda);
      if (result.ok) {
        agendaEdit(result.value, targetAgenda);
        return true;
      }
      alert(result.error.message);
      return false;
    };

    const onAgendaDelete = (targetAgenda: string): boolean => {
      const result = actions.validateAgendaOperation('delete', targetAgenda);
      if (result.ok) {
        agendaDelete(targetAgenda);
        return true;
      }
      alert(result.error.message);
      return false;
    };

    const agendaFilterBy = () => true;

    return (
      <div className={`${summaryFlag ? ' row g-2' : ''}`}>
        <div className={`summary-agenda clearfix${summaryFlag ? ' col-md-4 mt-0' : ''}`}>
          <AgendaSelect
            ref={refs.agendaSelectRef}
            typeaheadRef={refs.typeaheadRef}
            agendaFilterBy={agendaFilterBy}
            agenda={state.agenda}
            typeaheadShow={state.typeaheadShow}
            handleAgendaSelect={actions.handleAgendaSelect}
            handleAgendaInput={actions.handleAgendaInput}
            handleAgendaChange={actions.handleAgendaChange}
            agendaValue={state.agendaValue}
            onAgendaAdd={onAgendaAdd}
            onAgendaEdit={onAgendaEdit}
            onAgendaDelete={onAgendaDelete}
          />
        </div>
        <div className={`summary-bubble${summaryFlag ? ' col-md-8 opn_win' : ''}`}>
          <MinutesList ref={refs.minutesListRef} />
        </div>
      </div>
    );
  };
