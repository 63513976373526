import { makeAutoObservable } from "mobx"
import Messages, { MessagesClass } from "./Messages";
import { LocalStorage, StorageClass  } from "./LocalStorage";
import { audioContext, audioMasterAnalyserBus, playAudio } from "../util/audioContext";
import { JobQueue } from "../util/JobQueue";
import { textToSpeech } from "../util/TTS";
import AudioManager from "./AudioManager";
import { RecordButtonHandles } from '../types/speech'
import { RefObject } from "react";
import { DispatchEvent, STOP_ADVISER_AUDIO_EVENT } from "../util/eventListener";
import { SYSTEM_USER_ID } from '../util/util';
import { sentryLog } from "../util/sentry";

export enum MINUTE {
  'agenda' = 'agenda',
}

export interface RealtimeMinute {
  agenda: string;
  type: MINUTE;
  markdown: string;
  minutes?: string[];
  concerns?: string[];
  pending?: string[];
  decisions?: string[];
  next?: string[];
}

/**
 * Advisor Client Side State Manager.
 */
export class AdvisorState {
  isLoaded: boolean = false
  messageId: string  = ''
  audioAnalyser: AnalyserNode | null = null

  // 音声停止オーダー
  stopOrder: boolean = false
  llmAdvisorOrder: boolean = false

  private recorderRef: RefObject<RecordButtonHandles> | undefined;
  private beforeIsRecording: boolean = false;

  llmAdvisorMessage: string = ''

  minutes: RealtimeMinute[] = [];

  callbackRealtimeMinutesList = (minutes: RealtimeMinute[], targetAgenda?: string | null) => {}
  setRealtimeMinutesList = (agenda?: string | null) => {
    this.callbackRealtimeMinutesList(this.minutes, agenda);
  }
  updateMinute = (minutes: RealtimeMinute[], agenda?: string | null) => {
    this.minutes = minutes;
    this.setRealtimeMinutesList(agenda);
  }

  // TODO:
  //  This vocalChunk and textChunk is used to handle multiple advisor at once.
  //  this does not work due to the fact that there's only one JobQueue.
  //
  //  You can do this later:
  //  ttsQueue: Map<string, JobQueue>
  private vocalChunk: Map<string, string> = new Map()
  private textChunk:  Map<string, string> = new Map()
  private ttsQueue: JobQueue<AudioBuffer>
  private vocalizeQueue: JobQueue<string>

  constructor(
    private messageStore: MessagesClass,
    private localStorage: StorageClass,
  ) {
    makeAutoObservable(this)
    this.ttsQueue = new JobQueue(async _buffer => {}) // Noop
    this.vocalizeQueue = new JobQueue(async str => {}) // Noop
  }

  get isRunning() {
    return this.messageId !== ''
  }

  get isLoading() {
    return !this.isLoaded
  }

  // AIアドバイザーの能動的なメッセージが存在するかどうか
  get hasLlmAdvisorMessage() {
    return this.llmAdvisorMessage !== ''
  }

  setRecordRef(recorderRef: RefObject<RecordButtonHandles>): void {
    this.recorderRef = recorderRef;
  }

  private static async _setupEffectChain(input: AudioNode) {
    const reverb = new ConvolverNode(audioContext, {
      buffer: await AudioManager.getConvolverImpulse(),
    })
    const gain = new GainNode(audioContext, {
      gain: 0.2
    })

    input.connect(reverb)
    reverb.connect(gain)
    gain.connect(audioMasterAnalyserBus)
  }

  /**
   * Initializes Advisor.
   *
   * @param id -- unused new id allocated for advisor's message.
   */
  initialize(id: string) {
    this.llmAdvisorOrder = true;
    if(this.recorderRef && this.recorderRef.current){
      if(this.recorderRef.current.getIsRecording()){
        this.beforeIsRecording = true;
        try {
          this.recorderRef.current.stopRecording(true);
        } catch (e: any) {
          console.error('An error occurred while processing An error occurred while starting audio playback', e)
          sentryLog(e)
        }
      }else{
        this.recorderRef.current.disableToggle();
      }
    }

    this.messageStore.add({
      id,
      text: "",
      userId: SYSTEM_USER_ID,
      displayName: "アドバイザー",
      sourceLanguage: this.localStorage.language[0],
      translations: {},
    })

    const audioAnalyser = new AnalyserNode(audioContext, {
      fftSize: 2048,
    })

    audioAnalyser.connect(audioMasterAnalyserBus)

    this.isLoaded = false
    this.messageId = id
    this.audioAnalyser = audioAnalyser
    this.llmAdvisorMessage = ''

    this.vocalChunk.set(this.messageId, "")
    this.textChunk.set(this.messageId, "")

    this.ttsQueue = new JobQueue(buffer =>
      playAudio(buffer, audioAnalyser, this.stopOrder)
    )

    this.vocalizeQueue = new JobQueue(async text => {
      if(!this.stopOrder){
        const vocalBuffer = await textToSpeech(text, this.localStorage.language[0])
        if(!this.stopOrder && vocalBuffer) {
          this.ttsQueue.pushJob(vocalBuffer)
        }
      }
    })

    AdvisorState._setupEffectChain(this.audioAnalyser)
  }

  async feed(id: string, messageSegment: string) {
    if (!this.isLoaded) {
      this._setIsLoaded()
    }

    const text = (this.textChunk.get(id) ?? '') + messageSegment
    this.textChunk.set(id, text)

    // 音声停止オーダーがない場合は音声再生ジョブ追加
    if(!this.stopOrder){
      if (messageSegment.includes("。") ||
        messageSegment.includes("、") ||
        messageSegment.includes("!") ||
        messageSegment.includes("?") ||
        messageSegment.includes("！") ||
        messageSegment.includes("？"))
      {
        const totalVocalChunk = (this.vocalChunk.get(id) ?? '') + messageSegment
        this.vocalizeQueue.pushJob(totalVocalChunk)
        this.vocalChunk.set(id, '')
      } else {
        this.vocalChunk.set(id, (this.vocalChunk.get(id) ?? '') + messageSegment)
      }
    }
  }

  /**
   * finalizes ongoing messages.
   */
  async finish(id: string) {
    if (this.vocalChunk.has(id)) {
      const totalVocalChunk = this.vocalChunk.get(id)!

      if (totalVocalChunk !== '') {
        this.vocalizeQueue.pushJob(totalVocalChunk)
      }
    }

    await this.vocalizeQueue.stopAndJoin()
    await this.ttsQueue.stopAndJoin()
    // After await is different tick, move it to internal
    this._finishInternal(id)

    if(this.recorderRef && this.recorderRef.current){
      this.recorderRef.current.enableToggle();
      if(this.beforeIsRecording){
        try {
          this.recorderRef.current.startRecording();
        } catch (e: any) {
          console.error('An error occurred while finishing audio playback (Advisor)', e)
          sentryLog(e)
        }
      }
      this.beforeIsRecording = false;
    }

    // 音声停止オーダーを解除
    this.stopOrder = false;
    this.llmAdvisorOrder = false;
  }

  // AIアドバイザーの音声を停止する
  stop() {
    if (this.llmAdvisorOrder) {
      // 音声停止オーダーをいれる
      this.stopOrder = true;
    }
    // 音声停止イベント発火
    DispatchEvent(STOP_ADVISER_AUDIO_EVENT);
  }

  private _finishInternal(id: string) {
    const finalText = this.textChunk.get(id)!
    const messageItem = this.messageStore.get(id)
    messageItem?.setText(finalText)

    this.isLoaded = false
    this.messageId = ''
    this.audioAnalyser?.disconnect()
    this.audioAnalyser = null
    this.vocalChunk.delete(id)
    this.textChunk.delete(id)
  }

  private _setIsLoaded() {
    this.isLoaded = true
  }
}

const AdvisorStateInst = new AdvisorState(Messages, LocalStorage)
export default AdvisorStateInst
