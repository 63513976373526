import queryString from 'query-string'
import { RealtimeMinute } from "../store/AdvisorState";
import { ILanguages, ILanguageObject, LanguageList } from "./language";

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const _CHARSET = [
  ...[...Array(26)].map((e, i) => String.fromCharCode('A'.charCodeAt(0) + i)),
  ...[...Array(26)].map((e, i) => String.fromCharCode('a'.charCodeAt(0) + i)),
  ...[...Array(10)].map((e, i) => String.fromCharCode('0'.charCodeAt(0) + i)),
]

// オーナーID、ミーティングIDの初期値
export const INITIAL_ID = "internal_testing_01";
// サーバーから送信されるシステムメッセージのユーザーID
export const SYSTEM_USER_ID = "<SYSTEM>";

export const randomId = (chars = 12) => {
  let id = ''
  for (let i = 0; i < chars; i++) {
    id += _CHARSET[getRandomInt(0, _CHARSET.length - 1)]
  }
  return id
}

// 開発・ステージング環境かどうかの確認
export const isNotProduction = () => {
  const ENV = process.env.REACT_APP_ENVIRONMENT || 'development';
  return ENV !== 'production';
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent || input
}

export const parseQueryParam = (name: string, remove = false) => {
  const location = window.location
  const params = location.search

  let val = null
  if (params) {
    const parsed = queryString.parse(params)
    val = parsed[name]
    if (val && remove) {
      delete parsed[name]
      const newQuery = queryString.stringify(parsed)
      // remove param
      const newurl = location.protocol + '//' + location.host + location.pathname + (newQuery ? `?${newQuery}` : '')
      window.history.replaceState(window.history.state, '', newurl)
    }
  }

  return Array.isArray(val) ? val[0] : val
}

export const DEVICE = {
  UNKNOWN: 'unknown',
  WINDOWS_PHONE: 'Windows Phone',
  ANDROID: 'Android',
  IOS: 'iOS',
}

export const detectDevice = () => {
  const userAgent = navigator.userAgent || (navigator as any).vendor || (window as any).opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return DEVICE.WINDOWS_PHONE
  }

  if (/android/i.test(userAgent)) {
    return DEVICE.ANDROID
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return DEVICE.IOS
  }

  return DEVICE.UNKNOWN
}

// HALLUCINATION_WORDS is an array of regular expressions used to filter out certain phrases from the STT output.
// These phrases are known to be commonly misrecognized by the STT when it tries to recognize something from silence.
export const HALLUCINATION_WORDS = [
  // YouTube subscription stuff.
  /subscribe.+channel/i,
  /チャンネル登録/i,
  /視聴.*(?:ありがと|有難)う/i,
  /subscribe\scho/i,
  /thank\s?(?:s|you)\sfor\swatching!?/i,
  /đăng\ský\skênh/i,

  // Weird sound stuff.
  /ブーブー/i,
  /beep/i,
  /all\sright\./i,

  // Subtitle stuff.
  /amara\.org/i,
  /subtitle\sby/i,
  /transcribed\sby/i,
];

const _languageValues = Object.values(LanguageList);

const _getLanguageProperty = (property: keyof ILanguageObject) => {
  return _languageValues
    .map(language => language[property])
    .filter(value => value !== undefined && value !== '');
}

// クライアント画面で表示する会議全体の要約のタイトル名（全体要約）かどうか
export const isDefaultSummary = (text: string) => {
  return _getLanguageProperty('summaryAgenda').includes(text);
}

// どのアジェンダを選択していても内容が追加されるタイトル名（議事録）かどうか
export const isDefaultMinute = (text: string) => {
  const word = _getLanguageProperty('defaultAgenda');
  if (typeof word === 'string') {
    return word === text;
  }
  if (Array.isArray(word)) {
    for (const wordElement of word) {
      if (typeof wordElement === 'string') {
        if (wordElement === text) {
          return true;
        }
      }
    }
  }
  return word.includes(text);
}

// どのアジェンダを選択していても内容が追加されるタイトル名（議事録）かどうか
export const isFixedMinute = (text: string) => {
  const word = _getLanguageProperty('summaryAgenda');
  if (typeof word === 'string') {
    return word === text;
  }
  if (Array.isArray(word)) {
    for (const wordElement of word) {
      if (typeof wordElement === 'string') {
        if (wordElement === text) {
          return true;
        }
      }
    }
  }
  return word.includes(text);
}

// 「全体要約」または「議事録」かどうか
export const isDefaultMinutes = (text: string) => {
  if (isDefaultSummary(text)) {
    return true;
  }
  if (isDefaultMinute(text)) {
    return true;
  }
  return false;
}

// クライアント画面で表示する会議全体の要約の初期表示（全体要約）かどうか
export const isInitialSummary = (text: string) => {
  return _getLanguageProperty('initialSummary').includes(text);
}

// 「全体要約」以外の初期表示かどうか
export const isInitialMinute = (text: string) => {
  return _getLanguageProperty('initialMinutes').includes(text);
}

// 初期表示かどうか
export const isInitialMinutes = (text: string) => {
  if (isInitialSummary(text)) {
    return true;
  }
  if (isInitialMinute(text)) {
    return true;
  }
  return false;
}

// 「全体要約」または「議事録」のタイトル名を取得する
export const changeDefaultMinutes = (lng: string, agenda: string): string => {
  if (!isDefaultMinutes(agenda)) return agenda;
  return isDefaultSummary(agenda)
    ? getLanguageProperty(lng as ILanguages, 'summaryAgenda')
    : getLanguageProperty(lng as ILanguages, 'defaultAgenda');
};

// 初期表示を取得する
export const changeInitialMinutes = (lng: string, markdown: string): string => {
  if (!isInitialMinutes(markdown)) return markdown;
  return isInitialSummary(markdown)
    ? getLanguageProperty(lng as ILanguages, 'initialSummary')
    : getLanguageProperty(lng as ILanguages, 'initialMinutes');
};


// 言語を変更する
export const changeLanguageMinute = (lng: string, minute: RealtimeMinute) => {
  const change = minute;
  change.agenda = changeDefaultMinutes(lng, minute.agenda);
  change.markdown = changeInitialMinutes(lng, minute.markdown);
  return change;
}
export const changeLanguageMinutes = (lng: string, minutes: RealtimeMinute[]) => {
  const change = [];
  for (const minute of minutes) {
    change.push(changeLanguageMinute(lng, minute))
  }
  return change;
}
export const changeLanguageAgenda = (lng: string, agenda: string) => {
  return changeDefaultMinutes(lng, agenda);
}
export const changeLanguageAgendaList = (lng: string, agendaList: string[]) => {
  const change = [];
  for (const agenda of agendaList) {
    change.push(changeLanguageAgenda(lng, agenda))
  }
  return change;
}

// 指定した言語のプロパティを取得するヘルパー関数
function getLanguageProperty(lng: ILanguages, property: keyof ILanguageObject, defaultLng: ILanguages = 'ja-JP' as ILanguages): string {
  const value = LanguageList[lng]?.[property] ?? LanguageList[defaultLng]?.[property] ?? '';
  return typeof value === 'string' ? value : '';
}
