import './MinutesList.css'
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MINUTE, RealtimeMinute } from '../store/AdvisorState'
import { isInitialMinutes, changeLanguageAgenda, changeLanguageMinutes } from "../util/util";
import { LanguageList } from "../util/language";

// Types
type MinuteType = typeof MINUTE[keyof typeof MINUTE];
type MinuteSections = {
  [K in MinuteType]: {
    sections: string[];
    translations: string[];
  };
};

export interface MinutesListHandles {
  setRealtimeMinutes: (newMinutes: RealtimeMinute[]) => void;
  setAgenda: (newAgenda: string) => void;
}

interface MinutesListProps {
  onAgendaChange?: (agenda: string) => void;
}

interface MinuteAreaProps {
  agenda: string;
  markdown: string;
  realtimeMinute: RealtimeMinute;
}

const useMinuteContent = (type: MinuteType, markdown: string, realtimeMinute: RealtimeMinute) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (markdown === '' || isInitialMinutes(markdown)) {
      return markdown;
    }

    const sectionConfig: MinuteSections = {
      [MINUTE.agenda]: {
        sections: ['minutes', 'concerns', 'pending', 'decisions', 'next'],
        translations: ['議事録', '懸念事項', '保留事項', '決定事項', '宿題事項']
      }
    };

    const config = sectionConfig[type];
    if (!config) return markdown;

    let content = '';
    config.sections.forEach((section: string, index: number) => {
      const sectionContent = realtimeMinute[section as keyof RealtimeMinute];
      if (Array.isArray(sectionContent) && sectionContent.length > 0) {
        content += `## ${t(config.translations[index])}\n`;
        content += `- ${sectionContent.join('\n- ')}\n`;
      }
    });

    return content || markdown;
  }, [markdown, t, type, realtimeMinute]);
};

// Utility Functions
const formatMarkdown = (text: string): string => {
  return text
    .replace(/^```markdown\s*/, '')
    .replace(/^```\s*/, '')
    .replace(/```\s*$/, '');
};

// Components
const MinuteArea = ({ agenda, markdown, realtimeMinute }: MinuteAreaProps) => {
  const getContent = useMinuteContent(realtimeMinute.type as MinuteType, markdown, realtimeMinute);
  const [currentText, setCurrentText] = useState('');
  const markdownRef = useRef<HTMLDivElement>(null);

  // 前回のagendaとmarkdownを保持
  const previousAgendaRef = useRef(agenda);
  const previousMarkdownRef = useRef(markdown);

  useEffect(() => {
    setCurrentText(getContent);
    let timer: NodeJS.Timeout | null = null;
    if (markdownRef.current) {
      if (previousAgendaRef.current !== agenda || previousMarkdownRef.current !== markdown) {
        markdownRef.current.style.animation = 'to-color-change 1s 1';
        timer = setTimeout(() => {
          if (markdownRef.current) {
            markdownRef.current.style.animation = '';
          }
        }, 1000);
      } else {
        markdownRef.current.style.animation = '';
      }
    }
    previousAgendaRef.current = agenda;
    previousMarkdownRef.current = markdown;

    // タイマーがある場合のみクリーンアップ
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [agenda, getContent, markdown]);

  return (
    <div ref={markdownRef} className="markdown-container">
      <ReactMarkdown
        className="markdown"
        rehypePlugins={[rehypeRaw]}
      >
        {formatMarkdown(currentText)}
      </ReactMarkdown>
    </div>
  );
};

export const MinutesList = forwardRef<MinutesListHandles, MinutesListProps>(
  ({ onAgendaChange }: MinutesListProps, ref) => {
    const { i18n } = useTranslation();
    const [agenda, setAgenda] = useState<string>(
      (LanguageList['ja-JP'].defaultAgenda) as string
    );
    const [minutesList, setMinutesList] = useState<RealtimeMinute[]>([]);


    useEffect(() => {
      const handleLanguageChange = (lng: string) => {
        setAgenda(changeLanguageAgenda(lng, agenda));
        setMinutesList(prev => changeLanguageMinutes(lng, prev));
      };
      i18n.on('languageChanged', handleLanguageChange);
      return () => {
        i18n.off('languageChanged', handleLanguageChange);
      };
    }, [agenda, i18n]);

    const setRealtimeMinutes = useCallback((newMinutes: RealtimeMinute[]) => {
      setMinutesList([...newMinutes]);
    }, []);

    useImperativeHandle(ref, () => ({
      setRealtimeMinutes,
      setAgenda,
    }), [setRealtimeMinutes]);

    return (
      <>
        {minutesList.map((item) => (
          <div
            key={item.agenda}
            className={`minutes${item.agenda === agenda ? ' show' : ''}`}
          >
            <MinuteArea
              agenda={item.agenda}
              markdown={item.markdown}
              realtimeMinute={item}
            />
          </div>
        ))}
      </>
    );
  }
);
